import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import WhatsNew from "~/components/WhatsNew/WhatsNew";
export const _frontmatter = {
  "menuLabel": "What's New",
  "sortOrder": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`What's New`}</h1>
    <WhatsNew mdxType="WhatsNew" />
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "/whats-new-archive"
      }}>{`Pre 0.0.13 Release Archive`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      